const properties_dev = {
    title: 'Matlab Self-service',
    redirectSignIn: 'http://localhost:3000/',
    redirectSignOut: 'http://localhost:3000/',
    cognitoDomain: 'selfservice.auth.eu-central-1.amazoncognito.com',
    userPoolId: 'eu-central-1_iX1S8VuXd',
    appClientId: '3nd05a5sa8hac5nfi9vc5cjh9v', 
    identityPoolId: 'eu-central-1:0c2d24e6-a397-4514-9300-f54bb987136a',
    endpointName: "mlab",
    endpointUrl: "https://mlab-api.selfservice.hannover-re.cloud",
};
const properties_prod = {
    title: 'Matlab Self-service',
    redirectSignIn: 'https://mlab.selfservice.hannover-re.cloud/',
    redirectSignOut: 'https://mlab.selfservice.hannover-re.cloud/',
    cognitoDomain: 'selfservice.auth.eu-central-1.amazoncognito.com',
    userPoolId: 'eu-central-1_iX1S8VuXd',
    appClientId: '3nd05a5sa8hac5nfi9vc5cjh9v', 
    identityPoolId: 'eu-central-1:0c2d24e6-a397-4514-9300-f54bb987136a',
    endpointName: "mlab",
    endpointUrl: "https://mlab-api.selfservice.hannover-re.cloud",
};

let properties = properties_prod;

export { properties };